const courseCard = [
  {
    id: 1,
    img: "Images/image-4.jpg",
    title: "Institution Footprints",
    price: "₹9,999",
    courseUrl:'/course1',
  },
  /*{
    id: 2,
    img: "Images/image-4.jpg",
    title: "Future & Options",
    price: "₹10000",
    courseUrl:'/course2'
  },
*/
];

export default courseCard;
